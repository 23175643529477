import {listDocumentos as list} from '@/domain/documento/services'

const searchBem = function () {
    console.log('click window search doc...')
    let window = 'windowSearch'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Buscar Documento',
        width: '800',
        height: '600',
        minHeight: '500',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden bg-white',
        props: {
            columns: [
                {label: 'ID', value: 'id'},
                {label: 'Nome', value: 'name'},
                {
                    label: 'Código',
                    value: (v) => {
                        return v.code
                    }
                },
            ],
            fetchData: (terms, filters, done) => {
                console.log('Buscando dados: ', terms, filters, done)
                let rowsPerPage = 100
                list(rowsPerPage, filters.page, '&response=array&columns=simple&sortBy=id&descending=desc&finalidade=' + (this.finalidade || '') + '&search=' + terms)
                    .then(({data}) => {
                        let filter = function (terms, {field, list}) {
                            console.log(field)
                            const token = terms.toLowerCase()
                            return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
                        }

                        let filteredData = data.result

                        done(filteredData, {
                            page: filters.page,
                            rowsNumber: data.total, // response.totalRows,
                            rowsPerPage: rowsPerPage
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }, () => import('../../../layout/window/WindowSearch.vue'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                selected: (wid, val) => {
                    console.log('Selecionado com sucesso: ', val)
                    this.findedData = val
                    this.model = val.id
                    this.__emit(val.id)
                    this.description = val.id + ' - ' + val.name
                    setTimeout(() => {
                        this.$refs.input && this.$refs.input.focus()
                    }, 200)
                }
            })
            console.log(wid)
        }) // return wid
}

export default searchBem
