<template>
  <div class="conta-anexos"
       v-if="!bind"
       :class="{'dropzoneEnter': dropzone}"
       @dragenter.prevent.stop="dropzoneEnter"
       @dragover.prevent.stop="dropzone = true"
       @drop.prevent.stop="inputListener"
       @dragleave.prevent.stop="dropzone = false">
    <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
           v-show="false">
    <div class="conta-anexo-container non-selectable" v-if="!files.length && (!produto.anexos || !produto.anexos.length)">
      <div class="flex column items-center">
        <u-btn @click="$refs.input.click()" color="primary" icon="cloud-upload" size="sm"
               icon-type="fa" icon-style="light" noCaps
               label="Anexar arquivos"></u-btn>
        <div class="m-t">
          <span>ou solte os arquivos aqui <u-icon name="upload" type="fa" icon-style="light"
                                                  class="m-l-xs"/></span>
        </div>
      </div>
    </div>
    <div class="conta-anexos-list" v-else>
      <div v-for="(file, index) in produto.anexos" :key="file.d" class="conta-anexo-item m-r-sm m-b-sm">
        <img v-if="file.mimeType.startsWith('image/')" :src="file.url" />
        <u-icon v-else :name="getFileIco(file.filename)" type="fa" icon-style="solid" style="font-size: 220%">
        </u-icon>
        <u-tooltip :offset="[5,5]">{{ file.originalFilename }}</u-tooltip>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <!--<menu-options-item label="Excluir" close />-->
              <menu-options-item @click.native="download(file, true)" label="Visualizar" close/>
              <menu-options-item @click.native="download(file)" label="Baixar" close/>
              <menu-options-item @click.native="definirCapa(file)" label="Definir capa" close/>
              <menu-options-item @click.native="excluir(file)" label="Excluir" close/>
            </ul>
          </div>
        </u-popover>
      </div>
      <div v-for="(file, index) in files" :key="'tmp-' + index" class="conta-anexo-item m-r-sm m-b-sm pos-rlt">
        <u-icon :name="getFileIco(file.filename)" type="fa" icon-style="solid">
        </u-icon>
        <u-tooltip :offset="[5,5]">{{ file.filename }}</u-tooltip>
        <div class="pos-abt absolute-bottom w-full" style="min-height: 2px">
          <u-progress v-if="file.copying" :percentage="file.progress" :animate="true"
                      :color="file.progress < 85 ? 'grey' : 'positive'" height="2px"/>
          <span class="m-b-xs wrapper-xs" v-else-if="file.fail"><a @click.prevent.stop="upload(file)"
                                                                   style="text-transform: none; color: red">Falha. Tentar novamente</a></span>
          <template v-else></template>
        </div>
        <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
          <div>
            <ul>
              <!--<menu-options-item label="Excluir" close />-->
              <menu-options-item @click.native="excluir(file)" label="Excluir" close/>
            </ul>
          </div>
        </u-popover>
      </div>
      <div @click="$refs.input.click()" class="conta-anexo-item text-gre m-r-sm m-b-sm">
        <u-icon name="paperclip" type="fa" icon-style="solid"/>
        <u-tooltip :offset="[5,5]">Clique para anexar</u-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {
  downloadArquivoProduto,
  uploadArquivoProduto,
  excluirArquivoProduto,
  ajustaConfiguracoes
} from "@/domain/gerencia/produtos/services"
import uploadAreaMixin from "@/reuse/input/upload-area-mixin"
import {UTooltip, UProgress, UPopover} from "uloc-vue"
import MenuOptionsItem from "@/components/layout/context-menu/context-window-options-item"

export default {
  name: "ProdutoAnexo",
  inject: ['container'],
  mixins: [uploadAreaMixin],
  props: {
    produto: {
      required: true
    }
  },
  data() {
    return {
      bind: false,
      autoSend: true,
      uploadService: (data) => uploadArquivoProduto(this.produto.id, data),
      downloadService: (arquivo, inline) => downloadArquivoProduto(this.produto, arquivo, true),
      excluirService: (arquivo) => excluirArquivoProduto(this.produto.id, arquivo, true),
    }
  },
  methods: {
    uploadSuccessCallback(response, file) {
      this.files.splice(this.files.indexOf(file), 1)
      this.produto.anexos.push(response)
      /*this.bind = true
      this.$nextTick(() => {
        console.log('ZZZZZ', response)
        console.log(file)
        this.files[this.files.indexOf(file)].mimeType = response.mimeType
        this.files[this.files.indexOf(file)].filename = response.filename
        this.files[this.files.indexOf(file)].url = response.url
        this.$nextTick(() => {
          this.bind = false
        })
      })*/
    },
    uploadFailureCallback(error, file) {

    },
    excluir (file) {
      console.log('Excluindo arquivo')
      if (!file.id) {
        this.files.splice(this.files.indexOf(file), 1)
      } else {
        this.excluirService(file.id).then(() => {
          this.produto.anexos.splice(this.produto.anexos.indexOf(file), 1)
        }).catch(() => {
          alert("Error ao remover arquivo.")
        })
      }
    },
    definirCapa (file) {
      this.$uloc.loading.show()
      const capa = file.url
      ajustaConfiguracoes(this.produto.id, {capa: capa}).then(() => {
        this.$uloc.loading.hide()
        this.produto.configuracao.capa = capa
      }).catch((error) => {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      })
    }
  },
  components: {
    UTooltip,
    UProgress,
    UPopover,
    MenuOptionsItem
  }
}
</script>
